import React, { useState } from 'react';
import "../../css/Header.css";

function Header() {
    const [toggler, setToggler] = useState(false);

  return (
    <header>
        <img src='/images/header-img.png' className='header-img left' alt='Header img'/>
        <div className='company-and-nav-row'>
            <div className='company-row'>
                <div className='company-name'>
                    <p>Ритуальный салон "Память"</p>
                </div>
                <div className='number'>
                    <p>8-951-329-64-44</p>
                    <p>круглосуточно</p>
                </div>
            </div>
            <button className="navbar-toggler" type="button" aria-expanded={toggler ? "true" : "false"} onClick={() => {setToggler(!toggler)}}>
				<span className="icon-bar"></span>
				<span className="icon-bar"></span>
				<span className="icon-bar"></span>
			</button>
            <nav role="navigation" className='nav-panel' aria-expanded={toggler ? "true" : "false"}>
                <a href="/" className="nav-item">Главная</a>
                <a href="/catalog" className="nav-item">Каталог</a>
                <a href="/service" className="nav-item">Организация похорон</a>
                <a href="/contact" className="nav-item">Контакты</a>
            </nav>
        </div>
        <img src='/images/header-img.png' className='header-img right' alt='Header img'/>
    </header>
  );
}

export default Header;
