import React from "react";
import axios from "axios";
import { json, useLoaderData } from "react-router-dom";
import { catalog } from "./Generic/Catalog-subcatalog-url";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../css/Product.css";
import ReactYandexShare from "react-yandex-share";

function Product() {
    const {items} = useLoaderData();
    return (
        <>
            <h1>{items ? items.name : ""}</h1>
            <div className="catalog-content">
                <ul className="sections">
                    {catalog.map(({url, name}, i) => 
                        <li key={i}><a href={url} title={name}>{name}</a></li>
                    )}
                </ul>
                <div className="catalog-detail">
                    <div className="top-section">
                            <div className="carousel-slider">
                                <img src={items.img_url} alt={items.name}/>
                            </div>
                        <div className="information">
                            <h2>{items.name}</h2>
                            <hr></hr>
                            <p>{items.value ? items.value + " руб." : "Доступно под заказ"}</p>
                            <a className="question-btn" href="https://wa.me/79191346217?text=" title={"Оставить заказ"}>Оставить заказ</a>
                            <div className="share">
                                <hr></hr>
                                <p>Поделиться ссылкой:</p>
                                <script src="https://yastatic.net/share2/share.js" async></script>
                                <ReactYandexShare theme={{ lang: 'ru', services: 'vkontakte,odnoklassniki,telegram,viber,whatsapp,moimir', shape: "round"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="botton-section">
                        <h3>Характеристики</h3>
                        <table>
                            <tbody className="rows">
                                {Object.entries(items.description).map(([key, value], i) => 
                                    <tr key={i} className="char">
                                        <td className="char_name">
                                            <span>{key}</span>
                                        </td>
                                        <td className="dotted"></td>
                                        <td className="char_value">
                                            <span>{value}</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export const productLoader = async ({params}) => {
    const itemUrl = params.itemUrl;        
    //const res = await axios.get(`http://localhost/api/getProduct.php?item=${itemUrl}`)
    const res = await axios.get(`https://46ritual.ru/api/getProduct.php?item=${itemUrl}`)
    if (res.status !== 200 || res.data === ""){
        throw json({message: "Произошла ошибка!"}, {status: 404});
    }
    let items = res.data[0];
    items.description = JSON.parse(items.description)
    return {items};
}

export default Product;