import React from "react";
import { ReactComponent as Adress } from './img/address.svg';
import { ReactComponent as Phone } from './img/phone.svg';
import { ReactComponent as Clock } from './img/clock.svg';
import "../css/Contact.css";

function Contact(){
    return(
        <>
            <h1>Контакты</h1>
            <h2>Ритуальный салон "Память"</h2>
            <div className="content">
                <div>
                    <tbody className="information">
                        <tr>
                            <td>
                                <Adress/>
                            </td>
                            <td>
                                <p className="title">Адрес</p>
                                <p className="sub-title">Россия<br/>307170 Курская область<br/>г. Железногорск, ул. Курская д. 76А</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Clock/>
                            </td>
                            <td>
                                <p className="title">Режим работы</p>
                                <p className="sub-title">Пн. - Пт.: с 8:00 до 13:30<br/>Сб. - Вс.: с 8:00 до 12:00<br/>Праздничные дни: с 8:00 до 12:00</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Phone/>
                            </td>
                            <td>
                                <p className="title">Телефоны для консультаций</p>
                                <p className="sub-title">Руководитель - 8-919-134-62-17,<br/>8-951-322-46-66<br/>
                                Администратор - 8-951-329-64-44<br/>Бухгалтер - 8-910-316-41-46</p>
                            </td>
                        </tr>
                    </tbody>
                </div>
                <div>
                    <iframe title="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad65fa1536ec29a7c6afb726e8b37e7a6c7b87c60a3824719a17dd3067915e57b&amp;source=constructor" width="640" height="500" frameBorder={"0"}></iframe>
                </div>
            </div>
        </>
    );
}

export default Contact;