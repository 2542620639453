import React from "react";
import "../css/Service.css";
import { ReactComponent as Chevron } from './img/cursor.svg';

function Service() {
    const service = [
        {
            title:"Выезд агента на дом",
            img_url:"",
            description:"Поможем и дадим квалифицированную помощь по подготовке умершего к погребению, обслуживанию похорон."
        },
        {
            title:"Прием заказа по телефону",
            img_url:"",
            description:"Поможем и дадим квалифицированную помощь по подготовке умершего к погребению, обслуживанию похорон."
        },
        {
            title:"Автокатафалк",
            img_url:"/images/service/avtokatafalk.jpg",
            description:"Услуги автокатофалка оказываем по РФ, дальнее и ближнее зарубежье."
        },
        {
            title:"Доставка тела умершего из дома в морг",
            img_url:"/images/service/prima.jpg",
            description:"Окажем данную услугу по РФ и ближнее зарубежье."
        },
        {
            title:"Доставка тела умершего из морга на кладбище",
            img_url:"/images/service/prima.jpg",
            description:"Окажем данную услугу по РФ и ближнее зарубежье."
        },
        {
            title:"Доставка тела умершего по РФ и за границу",
            img_url:"/images/service/prima.jpg",
            description:"Окажем данную услугу по РФ и ближнее зарубежье."
        },
        {
            title:"Копка могилы в любом городе и населенном пункте РФ",
            img_url:"",
            description:"Окажем данную услугу по РФ и ближнее зарубежье."
        },
        {
            title:"Эксгумация трупа по разрешению следственного комитета",
            img_url:"",
            description:"Окажем данную услугу по РФ и ближнее зарубежье."
        },
        {
            title:"Похоронная бригада (вынос тела)",
            img_url:"",
            description:"Окажем данную услугу по РФ и ближнее зарубежье."
        },
        {
            title:"Автотранспорт",
            img_url:"/images/service/avtotransport.jpg",
            description:"Окажем данную услугу по РФ и ближнее зарубежье."
        }
    ];

    return(
        <>
            <h1>Организация похорон</h1>
            <div className="info-section">
                <h2>Ритуальный салон "Память" предоставляет следующие услуги</h2>
                <div className="items">
                {service.map(({title, img_url, description}, i) => 
                    <details key={i}>
                        <summary>
                            <div className="summary-title">{title}</div>
                            <div className="summary-chevron-up">
                                <Chevron/>
                            </div>
                            <div className="summary-chevron-down">
                                <Chevron/>
                            </div>
                        </summary>
                        <div className="summary-content">
                            {img_url !== "" ?
                                <div className="servise-img">
                                    <img src={img_url} alt={title}/>
                                </div>
                            :
                                <></>    
                            }
                            <div className="description">
                                <p>{description}</p>
                            </div>
                        </div>
                    </details>
                )}
                </div>
            </div>
        </>
    );
}

export default Service;