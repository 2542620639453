export const catalog = [
    {
        url:"/catalog/venki-i-korziny/",
        img_url:"/images/catalog/venki-i-korziny.webp",
        name:"Венки и корзины"
    },        
    {
        url:"/catalog/kresty/",
        img_url:"/images/catalog/kresty.webp",
        name:"Кресты"
    },
    {
        url:"/catalog/groby/",
        img_url:"/images/catalog/groby.webp",
        name:"Гробы"
    },
    {
        url:"/catalog/pamyatniki-i-ogradki/",
        img_url:"/images/catalog/pamyatniki-i-ogradki.webp",
        name:"Памятники и оградки"
    },
    {
        url:"/catalog/ritualnye-tovary/",
        img_url:"/images/catalog/ritualnye-tovary.webp",
        name:"Ритуальные принадлежности"
    },
    {
        url:"/catalog/tekstil/",
        img_url:"/images/catalog/tekstil.webp",
        name:"Текстиль"
    }
]

export const subCatalog = {
    "venki-i-korziny":
        [
            {
                url:"/catalog/venki-i-korziny/venki-0-8-m/",
                img_url:"/images/catalog/venki-0-8.webp",
                name:"Венки 0,8 м"
            },
            {
                url:"/catalog/venki-i-korziny/venki-1-m/",
                img_url:"/images/catalog/venki-1.webp",
                name:"Венки 1 м"
            },
            {
                url:"/catalog/venki-i-korziny/venki-1-2-m/",
                img_url:"/images/catalog/venki-1-2.webp",
                name:"Венки 1,2 м"
            },
            {
                url:"/catalog/venki-i-korziny/venki-1-5-m/",
                img_url:"/images/catalog/venki-1-5.webp",
                name:"Венки 1,5 м"
            },
            {
                url:"/catalog/venki-i-korziny/korziny-0-7-m/",
                img_url:"/images/catalog/korziny-0-7.webp",
                name:"Корзины 0,7 м"
            },                
            {
                url:"/catalog/venki-i-korziny/korziny-0-8-m/",
                img_url:"/images/catalog/korziny-0-8.webp",
                name:"Корзины 0,8 м"
            },
            {
                url:"/catalog/venki-i-korziny/korziny-0-9-m/",
                img_url:"/images/catalog/korziny-0-9.webp",
                name:"Корзины 0,9 м"
            },
            {
                url:"/catalog/venki-i-korziny/korziny-1-m/",
                img_url:"/images/catalog/korziny-1.webp",
                name:"Корзины 1 м"
            },
            {
                url:"/catalog/venki-i-korziny/korziny-1-2-m/",
                img_url:"/images/catalog/korziny-1-2.webp",
                name:"Корзины 1,2 м"
            },
            {
                url:"/catalog/venki-i-korziny/korziny-1-5-m/",
                img_url:"/images/catalog/korziny-1-5.webp",
                name:"Корзины 1,5 м"
            }
        ]
    ,
    "kresty":
        [
            {
                url:"/catalog/kresty/metal/",
                img_url:"/images/catalog/kresty-metal.webp",
                name:"Кресты металлические"
            },
            {
                url:"/catalog/kresty/derevo/",
                img_url:"/images/catalog/kresty-derevo.webp",
                name:"Кресты деревянные"
            }
        ]
    ,
    "groby":
        [
            {
                url:"/catalog/groby/lak/",
                img_url:"/images/catalog/groby-lak.webp",
                name:"Гробы лак"
            },
            {
                url:"/catalog/groby/obitye-tkanyu/",
                img_url:"/images/catalog/groby-obitye-tkanyu.webp",
                name:"Гробы обитые тканью"
            }
        ]
    ,
    "pamyatniki-i-ogradki":
        [
            {
                url:"/catalog/pamyatniki-i-ogradki/granit/",
                img_url:"/images/catalog/granit.webp",
                name:"Памятники гранит"
            },
            {
                url:"/catalog/pamyatniki-i-ogradki/ogradki/",
                img_url:"/images/catalog/ogradki.webp",
                name:"Оградки"
            }
        ]    
    ,
    "ritualnye-tovary":
        [
            {
                url:"/catalog/ritualnye-tovary/tovary/",
                img_url:"/images/catalog/tovary.webp",
                name:"Ритуальные принадлежности"
            },
            {
                url:"/catalog/ritualnye-tovary/produktsiya/",
                img_url:"/images/catalog/produktsiya.webp",
                name:"Ритуальная продукция"
            }
        ]
    ,
    "tekstil":
    [
        {
            url:"/catalog/tekstil/pokryvala/",
            img_url:"/images/catalog/pokryvala.webp",
            name:"Покрывала"
        },
        {
            url:"/catalog/tekstil/odezhda/",
            img_url:"/images/catalog/odezhda.webp",
            name:"Одежда"
        }
    ]
}