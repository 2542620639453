import { Outlet } from "react-router-dom";
import Footer from "./Generic/Footer";
import Header from "./Generic/Header";

const Layout = () => {
    return(
        <>
            <Header/>
            <main>
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
}

export {Layout};