import React from 'react';
import "../../css/Footer.css";

function Footer() {
  return (
    <footer>
        <div className='copyrite-panel'>
            <p>© 2023 Все права защищены.</p>
            <p>Ритуальный салон “Память”</p>
        </div>
        <div className='link-panel'>
            <a href="/" className="nav-item">Главная</a>
            <a href="/catalog" className="nav-item">Каталог</a>
            <a href="/contact" className="nav-item">Контакты</a>
        </div>
        <div className='number-panel'>
            <p>8-951-329-64-44</p>
            <p>8-919-134-62-17</p>
        </div>
    </footer>
  );
}

export default Footer;