import React, { useEffect } from "react";
import "../css/MainPage.css";

function MainPage(){
    useEffect(() => {
        document.body.classList.add('main-pg');
    }, [])
    
    return(
        <>
            <h1>О компании</h1>
            <h2>Ритуальный салон "Память"</h2>
            <div className="about-section">
                <div className="text-block">
                    <p className="title">Часы работы:</p>
                    <p>Пн. - Пт.: с 8:00 до 13:30<br/>Сб. - Вс.: с 8:00 до 12:00<br/>Праздничные дни: с 8:00 до 12:00</p>
                    <p className="title">Реализуем:</p>
                    <p>Гробы, кресты, венки, корзины, букеты, одежду для усопших, покрывала и прочий ритуальный товар.</p>
                    <p className="title">Поможем и дадим квалифицированную помощь по подготовке умершего к погребению, обслуживанию похорон.</p>
                </div>
                <div className="image-block">
                    <img src="/images/mainPage.webp" alt="Картинка компании"/>
                </div>
            </div>
            <h2>Юридическая информация</h2>
            <div className="legal-information">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p><b>Полное наименование:</b></p>
                            </td>
                            <td>
                                <p>ИП Ильина Мария Николаевна</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>Юридический адрес:</b></p>
                            </td>
                            <td>
                                <p>Россия 307170 Курская обл., г. Железногорск, ул. Арсенальная д. 5</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>Телефон, факс:</b></p>
                            </td>
                            <td>
                                <p>+7(47148) 4-88-31; +7-910-316-4146</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>ИНН:</b></p>
                            </td>
                            <td>
                                <p>463313993908</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>ОГРНИП:</b></p>
                            </td>
                            <td>
                                <p>319463200006592</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>Банковские реквизиты:</b></p>
                            </td>
                            <td>
                                <p>р/с: 40802810700520011322<br/>Филиал «Центральный» Банк ВТБ(ПАО), г. Москва<br/>К/с: 30101810145250000411<br/>БИК: 044525411</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );

}

export default MainPage;