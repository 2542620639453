import React from "react";
import { useParams } from 'react-router-dom';
import "../css/MainCatalog.css";
import {catalog, subCatalog} from "./Generic/Catalog-subcatalog-url";


export default function MainCatalog () {
    const { url } = useParams();

    const ShowElements = (items) => {
        return (
            items.map(({url, img_url, name}, i) => 
            <a className="catalog-item" key={i} href={url} title={name}>
                <div className="item-image">
                    <img src={img_url } alt={name}/>
                </div>
                <p>{name}</p>
            </a>
        ))
    }

    return(
        <>         
            <h1>Каталог</h1>
            <div className="catalog-content">
                <ul className="sections">
                    {catalog.map(({url, name}, i) => 
                        <li key={i}><a href={url} title={name}>{name}</a></li>
                    )}
                </ul>
                <div className="items-list">
                { url ? ShowElements(subCatalog[url]) : ShowElements(catalog)}
                </div>
            </div>
        </>
    );
}