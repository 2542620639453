import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import MainPage from './components/Main';
import Contact from './components/Contact';
import Service from './components/Service';
import { Layout } from './components/Layout';
import Product, { productLoader } from './components/Product';
import ProductCatalog, { productCatalogLoader } from './components/ProductCatalog';
import MainCatalog from './components/MainCatalog';
import ErrorPage from './components/ErrorPage';

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path={"/"} element={<Layout/>}>
      <Route index element={<MainPage/>}/>
      <Route path={"catalog/:url/:subUrl/:itemUrl"} element={<Product/>} loader={productLoader} errorElement={<ErrorPage/>}/>
      <Route path={"catalog/:url/:subUrl"} element={<ProductCatalog/>} loader={productCatalogLoader} errorElement={<ErrorPage/>}/>
      <Route path={"catalog/:url"} element={<MainCatalog/>}/>
      <Route path={"catalog"} element={<MainCatalog/>}/>
      <Route path={"service"} element={<Service/>}/>
      <Route path={"contact"} element={<Contact/>}/>
      <Route path={"*"} element={<Navigate to={"/"} replace/>}/>
    </Route>
  </>
)) 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

export default router;