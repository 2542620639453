import { useRouteError } from "react-router-dom";


export default function ErrorPage () {
    const error = useRouteError();

    return(
        <>
            <h2 className="error_text">{error.status}</h2>
            <h2 className="error_text">{/*error.data.message*/"Произошла ошибка!"}</h2>
        </>
    );
}