import { json, useLoaderData } from "react-router-dom";
import axios from "axios";
import {catalog, subCatalog} from "./Generic/Catalog-subcatalog-url";

export default function ProductCatalog () {
    const {items, url} = useLoaderData();

    const submenu = (url) => {
        return (
            <ul className="submenu">
                {subCatalog[url].map(({url, name}, i) => 
                    <li key={i}>
                        <a href={url} title={name}>{name}</a>
                    </li>
                )}
            </ul>
        );
    }

    return(
        <>
            <h1>Каталог</h1>
            <div className="catalog-content">
                <ul className="sections">
                    {catalog.map((item, i) =>
                        <li key={i} className={item.url.includes(url) ? "active" : ""}>
                            <a href={item.url} title={item.name}>{item.name}</a>
                            {item.url.includes(url) ? submenu(url) : <></>}
                        </li>
                    )}
                    
                </ul>
                <div className="items-list fixed">
                {items ? items.map(({url, img_url, name, value}, i) => 
                    <a className="item-card" key={i} href={url} title={name}>
                        <div className="item-card-image">
                            <img src={img_url} alt={name}/>
                        </div>
                        <p>{name}</p>
                        <p className="value">{value ? `${value} руб.` : `Доступно под заказ`}</p>
                    </a>
                )
                :
                <h2 className="error_text">Товары отсутствуют!</h2> 
                }
                </div>
            </div>
        </>
    );
}

export const productCatalogLoader = async ({params}) => {
    const subUrl = params.subUrl;
    //const res = await axios.get(`http://localhost/api/getProduct.php?type=${subUrl}`)
    const res = await axios.get(`https://46ritual.ru/api/getProduct.php?type=${subUrl}`)
    if (res.status !== 200){
        throw json({message: "Произошла ошибка!"}, {status: 404});
    }
    const items = res.data;
    return {items, url: params.url}
}